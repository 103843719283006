import { createStore } from 'vuex';
import accountOptions from "@/store/accountOptions";
import chainOptions from "@/store/chainOptions";
import assertOptions from "@/store/assertOptions";
import mobileOptions from "@/store/mobileOptions";

export default createStore({
    modules: {
        accountInfo: accountOptions,
        chainInfo: chainOptions,
        assertInfo: assertOptions,
        mobileInfo: mobileOptions,
    }
})