export default {
    namespaced: true,
    state: {
        data: [],
        nft_data: [],
        currentAcquisitionStatus: false
    },

    mutations: {
        setData(state, data) {
            state.data = data;
        },
        setNftData(state, data) {
            state.nft_data = data;
        },
        setCurrentAcquisitionStatus(state, data) {
            state.currentAcquisitionStatus = data;
        }
    }
}