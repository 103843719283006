<script>
import HomePage2 from "@/components/HomePage2.vue";

export default {
  name: "MyIndex",
  components: {
    HomePage2
  }
}
</script>

<template>
  <HomePage2></HomePage2>
</template>

<style scoped lang="less">

</style>