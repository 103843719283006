export default {
    namespaced: true,
    state: {
        isMobile: false,
        useOtherWallet: false
    },
    mutations: {
        setIsMobile(state, isMobile) {
            state.isMobile = isMobile;
        },
        setUseOtherWallet(state, useOtherWallet) {
            state.useOtherWallet = useOtherWallet;
        }
    },
    actions: {}
}