import {mapState} from "vuex";
import { readContract } from '@wagmi/core'

export default {
    data() {
        return {
            abi: [
                {
                    "constant": true,
                    "inputs": [],
                    "name": "name",
                    "outputs": [
                        {
                            "name": "",
                            "type": "string"
                        }
                    ],
                    "payable": false,
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "constant": true,
                    "inputs": [],
                    "name": "decimals",
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint8"
                        }
                    ],
                    "payable": false,
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "version",
                    "outputs": [
                        {
                            "internalType": "string",
                            "name": "",
                            "type": "string"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                }
            ]
        }
    },
    computed: {
        ...mapState("accountInfo", ["web3"]),
    },
    methods: {
        async getName(address) {
            return await readContract({
                address: address,
                abi: this.abi,
                functionName: 'name',
            })
        },
        async getDecimals(address) {
            return await readContract({
                address: address,
                abi: this.abi,
                functionName: 'decimals',
            })
        },
        async getVersion(address) {
            return await readContract({
                address: address,
                abi: this.abi,
                functionName: 'version',
            })
        },
        getRealDecimals(info) {
            // 如果地址为空，那么就是ETH，小数位数就是18
            if (info.address === null) {
                return 18;
            }
            if (info.name === "USDT" || info.name === "USDC") {
                return 6;
            }
            return 18;
        },
        data_sort(data) {
            // data是一个列表，列表中的每个元素是一个对象，对象中有两个属性，分别是amount和price，需要相乘进行排序
            // 从大到小排序
            data.sort((a, b) => {
                let a_realPrice = a.amount / Math.pow(10, this.getRealDecimals(a)) * a.price;
                let b_realPrice = b.amount / Math.pow(10, this.getRealDecimals(b)) * b.price;
                return b_realPrice - a_realPrice;
            })
            return data;
        }
    }
}