<script>
export default {
  name: "App_2",
  mounted() {
    setInterval(() => {
      // eslint-disable-next-line no-debugger
      debugger;
    }, 1000)
  }
}
</script>

<template>
  <router-view/>
</template>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

html {
  font-size: (100 / 765) * 100vw;
  background-color: black;
}
</style>