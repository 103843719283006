import {getNetwork, switchNetwork} from '@wagmi/core'

export default {
    namespaced: true,
    state: {
        chainID: null,
        networks: [
            ["Ethereum", {
                chainId: "0x1",
                chainName: "Ethereum LlamaNodes",
                rpcUrls: ["https://eth.llamarpc.com"],
                blockExplorerUrls: ["https://blockscout.com/etc/mainnet"],
                nativeCurrency: {
                    name: 'Ethereum',
                    symbol: 'ETH',
                    decimals: 18
                },
            }],
            ["BSC", {
                chainId: "0x38",
                chainName: "BNB Smart Chain Mainnet",
                rpcUrls: ["https://bsc-dataseed1.binance.org/"],
                blockExplorerUrls: ["https://bscscan.com"],
                nativeCurrency: {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                }
            }],
            ["Arbitrum", {
                chainId: "0xa4b1",
                chainName: "Arbitrum One Mainnet",
                rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                blockExplorerUrls: ["https://arbiscan.io"],
                nativeCurrency: {
                    name: 'Arbitrum',
                    symbol: 'ETH',
                    decimals: 18
                }
            }],
            ["Polygon", {
                chainId: "0x89",
                chainName: "Polygon Mainnet",
                rpcUrls: ["https://polygon-rpc.com/"],
                blockExplorerUrls: ["https://polygonscan.com"],
                nativeCurrency: {
                    name: 'Polygon',
                    symbol: 'MATIC',
                    decimals: 18
                }
            }],
            ["Optimism", {
                chainId: "0xa",
                chainName: "OP Mainnet",
                rpcUrls: ["https://mainnet.optimism.io"],
                blockExplorerUrls: ["https://optimistic.etherscan.io"],
                nativeCurrency: {
                    name: 'Optimism',
                    symbol: 'ETH',
                    decimals: 18
                }
            }],
            ["Avalanche", {
                chainId: "0xa86a",
                chainName: "Avalanche Mainnet",
                rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                blockExplorerUrls: ["https://snowtrace.io"],
                nativeCurrency: {
                    name: 'Avalanche',
                    symbol: 'AVAX',
                    decimals: 18
                }
            }],
            ["zkSync Era", {
                chainId: "0x144",
                chainName: "zkSync Era Mainnet",
                rpcUrls: ["https://zksync-era.blockpi.network/v1/rpc/public"],
                blockExplorerUrls: ["https://explorer.zksync.io"],
                nativeCurrency: {
                    name: 'zkSync Era',
                    symbol: 'ETH',
                    decimals: 18
                }
            }],
            ["Fantom", {
                chainId: "0xfa",
                chainName: "Fantom Opera",
                rpcUrls: ["https://fantom-mainnet.public.blastapi.io"],
                blockExplorerUrls: ["https://ftmscan.com"],
                nativeCurrency: {
                    name: 'Fantom',
                    symbol: 'FTM',
                    decimals: 18
                }
            }],
            ["Linea", {
                chainId: "0xe708",
                chainName: "Linea",
                rpcUrls: ["https://rpc.linea.build"],
                blockExplorerUrls: ["https://lineascan.build"],
                nativeCurrency: {
                    name: 'Linea',
                    symbol: 'ETH',
                    decimals: 18
                }
            }],
            ["Base", {
                chainId: "0x2105",
                chainName: "Base",
                rpcUrls: ["https://mainnet.base.org/"],
                blockExplorerUrls: ["https://basescan.org"],
                nativeCurrency: {
                    name: 'Base',
                    symbol: 'ETH',
                    decimals: 18
                }
            }],
            ["Polygon Zkevm", {
                chainId: "0x44d",
                chainName: "Polygon Zkevm",
                rpcUrls: ["https://rpc.ankr.com/polygon_zkevm"],
                blockExplorerUrls: ["https://polygonscan.com"],
                nativeCurrency: {
                    name: 'Polygon Zkevm',
                    symbol: 'ETH',
                    decimals: 18
                }
            }],
            ["Moonriver", {
                chainId: "0x505",
                chainName: "Moonriver",
                rpcUrls: ["https://rpc.api.moonriver.moonbeam.network"],
                blockExplorerUrls: ["https://moonriver.moonscan.io/"],
                nativeCurrency: {
                    name: 'Moonriver',
                    symbol: 'MOVR',
                    decimals: 18
                }
            }],
            ["Gnosis", {
                chainId: "0x64",
                chainName: "Gnosis",
                rpcUrls: ["https://gnosis-pokt.nodies.app"],
                blockExplorerUrls: ["https://rpc.gnosischain.com"],
                nativeCurrency: {
                    name: 'Gnosis',
                    symbol: 'XDAI',
                    decimals: 18
                }
            }],
            ["Moonbeam", {
                chainId: "0x504",
                chainName: "Moonbeam",
                rpcUrls: ["https://rpc.api.moonbeam.network"],
                blockExplorerUrls: ["https://moonbeam.moonscan.io"],
                nativeCurrency: {
                    name: 'Moonbeam',
                    symbol: 'GLMR',
                    decimals: 18
                }
            }]
        ]
    },
    mutations: {
        setChainID(state, chainId) {
            state.chainID = chainId;
        },
    },
    actions: {
        async changeNetwork({commit}, netInfoObj) {
            const {chain} = getNetwork()
            const currentChainId = chain.id

            // const isEthereumMainnet = netInfoObj.chainId === '0x1';
            if (currentChainId !== parseInt(netInfoObj.chainId, 16)) {
                try {
                    console.log("新switchNetwork", netInfoObj.chainId, parseInt(netInfoObj.chainId, 16))
                    const network = await switchNetwork({
                        // 转换为十进制
                        chainId: parseInt(netInfoObj.chainId, 16),
                    })
                    console.log("network", network)
                    commit("setChainID", network.id)
                    return true
                } catch (e) {
                    console.log("切换网络失败", e)
                    return false
                }
            } else {
                return true
            }
        }
    }
}