import axios from "@/axios";

export default {
    namespaced: true,
    state: {
        account: null,
        connectionStatus: false,
        web3: null,
        unlock_assets: null
    },
    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
        setWeb3(state, web3) {
            state.web3 = web3;
        },
        async setUnlockAssets(state, address) {
            await axios.get("/api/get_unlocked_balance/", {
                params: {
                    address
                }
            }).then(res => {
                const num = res.data.data
                state.unlock_assets = num.toString().replace(/(?=(\B\d{3})+$)/g, ",");
            })
        }
    }
}