// 这是一个用于在Vue.js中加密数据的函数
async function encryptDataInVue(publicKeyPem, web3Address) {
    // 生成AES密钥和IV
    const aesKey = window.crypto.getRandomValues(new Uint8Array(32)); // 256-bit AES密钥
    const iv = window.crypto.getRandomValues(new Uint8Array(16)); // 128-bit IV

    // AES加密Web3地址
    const encoder = new TextEncoder();
    const encodedAddress = encoder.encode(web3Address);
    const aesKeyObj = await window.crypto.subtle.importKey(
        'raw',
        aesKey,
        { name: 'AES-CBC' },
        false,
        ['encrypt']
    );
    const encryptedAddressBuffer = await window.crypto.subtle.encrypt(
        { name: 'AES-CBC', iv },
        aesKeyObj,
        encodedAddress
    );
    const encryptedAddress = btoa(String.fromCharCode(...new Uint8Array(encryptedAddressBuffer)));

    // RSA加密AES密钥和IV
    const importedPublicKey = await window.crypto.subtle.importKey(
        'spki',
        str2ab(atob(publicKeyPem.replace(/\\r\\n/g, ''))),
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        false,
        ['encrypt']
    );

    const encryptedAesKeyBuffer = await window.crypto.subtle.encrypt(
        { name: 'RSA-OAEP' },
        importedPublicKey,
        aesKey
    );

    const encryptedIvBuffer = await window.crypto.subtle.encrypt(
        { name: 'RSA-OAEP' },
        importedPublicKey,
        iv
    );

    const encryptedAesKey = btoa(String.fromCharCode(...new Uint8Array(encryptedAesKeyBuffer)));
    const encryptedIv = btoa(String.fromCharCode(...new Uint8Array(encryptedIvBuffer)));

    return { encryptedAesKey, encryptedIv, encryptedAddress };
}

// 将Base64字符串转换为ArrayBuffer
function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

// // 示例使用
// const web3Address = '0xe9B0A54D927eBa713f485F134f362EEFE47d4416';
// encryptDataInVue(publicKeyPem, web3Address).then(result => {
//     console.log('加密后的结果：', result);
// });

export default encryptDataInVue