import {createApp} from 'vue';
import App from './App_2.vue'
import store from "@/store/index";
import route from "@/route";
import Button from 'ant-design-vue/lib/button';
import Modal from 'ant-design-vue/lib/modal';

const app = createApp(App)
// app.use(Antd)
app.use(store).use(route).use(Button).use(Modal)

// 禁用右键菜单
document.addEventListener('contextmenu', event => event.preventDefault());

// 禁用 F12 键
document.addEventListener('keydown', event => {
    if (event.keyCode === 123) { // F12 的键码
        event.preventDefault();
    }
});


app.mount('#app')
